/**
 * Application theme
 *
 * Override and extend smooth-ui theme values
 * https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/theme.js
 * https://smooth-ui.smooth-code.com/
 */

import { darken, lighten, transparentize } from 'polished';
import { theme, th, thd } from '@smooth-ui/core-sc';
import settingsApp from 'theme/settingsApp';

/*
If overwriting the default Smooth-UI theme properties, and you're planning on
re-using them throughout the default export below, make sure to define them in
the outer scope, otherwise components attempting to use the value with throw errors
*/
const primary = '#0E1934'; // Buttons, inputs, etc
const secondary = '#DB2027';
const themeColor = '#4b525b';

export default {
  // Mandatory
  ...theme,
  settingsApp, // Bring in the merged app settings

  // Resets
  // borderRadius: 0,
  primary: primary,
  secondary: secondary,
  dark: th('gray600'),
  themeColor: themeColor,
  defaultFontColor: themeColor,

  // Custom Colours
  successLight: th('success', c => transparentize(0.75, c)),
  dangerLight: th('danger', c => transparentize(0.75, c)),
  primaryLight: th('primary', c => transparentize(0.75, c)),

  // Custom
  customHeaderBackground: primary,
  customHeaderColor: '#fff',
  customMenuHighlight: theme.gray900,
  customMenuHighlightColor: '#fff',
  customMenuItemBackground: secondary,
  customMenuItemBorderBottom: secondary,
  customMenuFocus: lighten(0.05, secondary),
  customSidebarMenuBackground: primary,
  customSidebarMenuFontSize: '0.8rem',
  customSidebarMenuColor: '#fff',
  customSidebarMenuHighlight: '#f4bd19',
  customSidebarMenuHighlightColor: '#fff',
  linkColor: '#062333',

  // Input + Buttons
  buttonBlockSpacerTop: '2rem',
  buttonBlockSpacerBottom: '1rem',
  inputBtnMinWidth: '120px',
  customInputBtnFontWeight: '700',
  customInputHintColor: theme.gray600,
  customInputHintFontSize: '.875em',
  customInlineInputInvalidColor: theme.red,
  customCheckboxDisabledBackground: '#dee2e6',

  // Loading animations
  loadingBarColor: primary || '#f4bd19',
  loadingSpinnerPrimary: themeColor || '#555',
  loadingSpinnerSecondary: '#eee',

  // Tabs
  customTabsBackground: theme.gray200,
  // customTabsColor: themeColor,
  customTabsBackgroundSelected: theme.gray400,
  // customTabsColorSelected: themeColor,

  // Dashboard
  dashboardHeaderBackground: th('customHeaderBackground'),
  dashboardHeaderColor: th('customHeaderColor'),
  dashboardBodyBackground: '#fff',
  dashboardBorderColor: th('customHeaderBackground'),

  // Graphs
  graphColors: [
    '#eb885b',
    '#87a3ae',
    '#323a44',
    '#1d967a',
    '#afd886',
    '#fbc947',
    '#c1272d',
    '#1d6477',
    '#ab9c5b',
    '#8f8675',
    '#e6cd8b',
    '#11939d',
    '#c098ae',
    '#70272d',
    '#919393',
    '#335a33',
  ],

  // Spotlight search (Jump to page)
  spotlightBgColor: darken(0.05, themeColor),
  spotlightBorderColor: darken(0.1, themeColor),
  spotlightSuccess: darken(0.5, themeColor), // Override success border colour
  spotlightTextColor: '#fff',
  spotlightPlaceholder: lighten(0.9, themeColor),
  spotlightDropdownBackground: lighten(0.2, themeColor),
  spotlightDropdownHover: lighten(0.1, themeColor),
  spotlightDropdownActive: lighten(0.2, themeColor),
  spotlightShortcutBackground: lighten(0.2, themeColor),
  spotlightShortcutBorder: lighten(0.1, themeColor),
  spotlightShortcutColor: darken(0.25, themeColor),

  // Statements
  statementHighlightColor: '#0E1934',
  statementStatus: {
    achieved: '#5C8AFF',
    onTrack: '#5C8AFF',
    close: '#ea7d23',
    behind: '#e02a27',
    inProgress: '#ea7d23',
    timeElapsed: '#ED2424',
  },
  statementDoughnutBorder: '#eaeaeb',
  statementDoughnutRemainingTrack: '#D8D8D8',

  // Tooltips
  tooltipBackground: '#fff',
  tooltipBorderColor: '#fff',
  tooltipColor: thd('defaultFontColor', theme.gray800),
};
